body {
  font-family: sans-serif;
}

.ais-SearchBox {
  margin: 1em 0;
}

.ais-Pagination {
  margin-top: 1em;
}

.left-panel {
  float: left;
  width: 250px;
}

.right-panel {
  margin-left: 260px;
}

.ais-InstantSearch {
  /*max-width: 960px;*/
  max-width: 1000px;
  overflow: hidden;
  margin: 0 auto;
}

.ais-Hits-item {
  margin-bottom: 1em;
  width: calc(100% - 1rem);
  border: 0;
}

.ais-Hits-item img {
  margin-right: 1em;
}

.hit-name {
  margin-bottom: 0.5em;
}

.hit-description {
  color: #888;
  font-size: 14px;
  margin-bottom: 0.5em;
}
/* Added by me*/
.right-panel {
  margin-left: 2px;
}
.ais-Hits-item,
.ais-InfiniteHits-item,
.ais-InfiniteResults-item,
.ais-Results-item {
  margin-top: 1rem;
  margin-left: 1rem;
  padding: 1rem;
  width: calc(100% - 1rem);
  border: 0px;
}

.text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.Highlight {
  background-color: #ffd54f;
}

html#ecwid_html body#ecwid_body .hc-widget .hc-product-tabs#hc-product-tabs {
  padding: 0 20px;
  width: 90% !important;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}